import * as styles from './examples-page.module.scss';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Button from '../components/button';
import { Examples } from '../content/examples';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from '../components/v2/footer';
import Header from '../components/v2/header';
import React from 'react';
import SEO from '../components/seo';
import Section from '../components/section';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { graphql } from 'gatsby';

const ExamplesPageTemplate = ({ pageContext, data }) => {
  const {
    slug,
    label,
    description,
    heading,
    subheading,
    explainerContent: explainerParagraphs,
  } = pageContext;

  const examples = Examples[slug].map((example) => ({
    ...example,
    image: data.allFile.edges.find((edge) => edge.node.name === example.id)?.node.childImageSharp
      .gatsbyImageData,
  }));

  return (
    <>
      <SEO title={`${label} Quiz Examples`} description={description} />
      <Header
        btnLabel={
          <>
            Get started <FontAwesomeIcon icon={faArrowRight} />
          </>
        }
        btnLink="/plans/"
        btnTheme="primary"
      />
      <main className={styles.industryLandingPage}>
        <Section theme="white" style={{ padding: `5rem 0`, marginTop: `4.5rem` }}>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8 text-center">
              <h1 className="color-black text-center" style={{ marginBottom: `2.5rem` }}>
                {label}
                <br />
                Quiz Examples
              </h1>
              <h2 className="text-body color-muted-foreground text-center">{subheading}</h2>
              <div className={styles.buttons}>
                <Button
                  theme="primary"
                  size="large"
                  type="internal"
                  href="/plans/"
                  style={{ gap: '12px' }}>
                  Get started <FontAwesomeIcon icon={faArrowRight} />
                </Button>
                <Button
                  theme="outline"
                  size="large"
                  type="internal"
                  href="/contact/"
                  style={{ gap: '12px' }}>
                  Talk to an expert
                </Button>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginTop: `5rem` }}>
            {examples.map((example, index) => (
              <div
                className="col-md-6 col-lg-4 col-xl-3"
                key={index}
                style={{ '--brand-color': example.color }}>
                <div className={styles.customerCard}>
                  <GatsbyImage image={getImage(example.image)} alt={example.name} />
                  <div className={styles.customerCardBackground}></div>
                  <div className={styles.customerCardSolidBackground}></div>
                  <div className={styles.customerCardContent}>
                    <p className="heading-lg color-white">{example.name}</p>
                    <p
                      className="heading-sm color-white"
                      style={{ lineHeight: '1.25', marginTop: '0.25rem' }}>
                      {example.description}
                    </p>
                    <Button
                      theme="white"
                      href={example.quizUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: 'var(--brand-color)' }}>
                      Take Quiz
                    </Button>
                  </div>
                  <div className={styles.customerCardOverlay}>
                    <p className="heading-lg color-white">{example.quizTitle}</p>
                    <Button
                      theme="white"
                      href={example.quizUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: 'var(--brand-color)' }}>
                      Take Quiz
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Section>
        <Section theme="grey">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8 text-center">
              <h3 className="heading-lg">
                How we chose these {label.toLowerCase()} quiz examples.
              </h3>
              <div className="text-body">
                {explainerParagraphs.map((paragraph, index) => (
                  <p key={index} className="text-body">
                    {paragraph}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </Section>
        <Section theme="purple500" style={{ padding: `5rem 0` }}>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <p className="heading-xl color-white">
                Ready to create your own {label.toLowerCase()} quiz?
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xl-6 text-center">
              <p
                className="heading-sm color-white text-center"
                style={{ marginTop: '0', marginBottom: '2rem' }}>
                Create your first quiz in minutes with our full-featured quiz builder and start
                connecting with your customers.
              </p>
              <Button
                theme="white"
                size="large"
                type="internal"
                href="/plans/"
                style={{ gap: '12px' }}>
                Get started <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </div>
          </div>
        </Section>
      </main>
      <Footer />
    </>
  );
};

export default ExamplesPageTemplate;

export const query = graphql`
  query {
    allFile(filter: { relativePath: { regex: "/examples/" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(width: 600, quality: 100, placeholder: BLURRED)
          }
          name
        }
      }
    }
  }
`;
